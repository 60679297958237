export default {
  data() {
    return {
      isEditMode: false,
      submitButton: {
        label: this.$t('actions.submit'),
        block: true
      }
    };
  },
  computed: {
    breadcrumbTitle() {
      return this.$t(`views.breadcrumbs.${this.isEditMode ? 'edit' : 'new'}`,
        {entity: this.$tc('models.' + this.modelName.charAt(0).toLowerCase() + this.modelName.slice(1) + '.entity', 1)});
    }
  },
  methods: {
    suggestTags(val) {
      this.searchTags({...this.options, filterBy: { key: val, model: 'SearchableItem' }}).then(() => {
        this.fields.searchableItemAttributes.searchData = this.tagsList.items;
      });
    },
    handleSubmit(data) {
      const recordData = this.createFormData(data);
      this.processSubmission(recordData);
    },
    processSubmission(recordData) {
      if (this.isEditMode) {
        const form = {
          recordId: this.record.id,
          form: recordData
        };
        this.updateRecord(form).then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: this.modelName + 'List' });
          }
        });
      } else {
        this.createRecord(recordData).then((response) => {
          if (response.status === 200) {
            this.$router.push({ name: this.modelName + 'List' });
          }
        });
      }
    },
    createFormData(data) {
      const recordData = new FormData();
      for (const key in data) {
        if (data[key] || data[key] === false) {
          recordData.append(key, data[key]);
        }
      }
      return recordData;
    },
    prepareForm() {
      let currentRecord = this.$route.params.id;
      if (currentRecord) {
        this.isEditMode = true;
        this.getRecord({id: this.$route.params.id}).then(() => {
          this._.forEach(this.fields, (field) => {
            if (field.type === 'file') {
              this.fields[field.key].origFile = this.record[field.key];
            } else {
              this.fields[field.key].value = this.record[field.key];
            }
            if (this.isEditMode) {
              this.fields[field.key].valid = true;
            }
          });
        });
      }
    }
  },
  mounted() {
    if (this.$route.params.id)
      this.prepareForm();
  }
};
