export default {
  methods: {
    createFormData(data) {
      const formData = new FormData();
      for (const key in data) {
        if (key === 'searchable_item_attributes') {
          formData.append(key + '[tag_list]', data[key]);
        } else if (key === 'image' && data[key] === '') {
          continue;
        } else {
          formData.append(key, data[key]);
        }
      }
      return formData;
    }
  }
};

